import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { usePrismicDocumentByUID } from "@prismicio/react";
import ArtistPage from "./pages/ArtistPage";
import MainPage from "./pages";

import Snow from "./components/Snow";

import "./App.scss";

const App = () => {
  const [document] = usePrismicDocumentByUID(
    "concours",
    window.location.pathname.split("/")[1]
  );
  const [artistsData, setArtistsData] = useState(null);
  const [globalData, setGlobalData] = useState(null);

  useEffect(() => {
    if (document && document.data && !artistsData) {
      setArtistsData(document.data.artist);
      setGlobalData(document.data);
    }
  }, [document]);

  console.log(window.location.pathname.split("/")[1]);
  console.log(document);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {artistsData &&
            artistsData
              .map((el) => document.uid + "/" + el.path[0].text)
              .map((path, index) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <ArtistPage
                      globalData={globalData}
                      index={index}
                      document={document}
                      artistsData={artistsData}
                    />
                  }
                />
              ))}
          <Route
            path="*"
            element={
              <MainPage
                document={document}
                artistsData={artistsData}
                globalData={globalData}
              />
            }
          />
        </Routes>
      </BrowserRouter>
      {globalData && globalData.showsnow && <Snow />}
    </>
  );
};

export default App;
