import { Link } from "react-router-dom";
import "./index.scss";

const MainLogos = ({ data, uid }) => {
  return (
    <div className="mainLogos">
      {data && data.logo1?.url && (
        <Link to={"/" + uid}>
          <img className="mainIcon" src={data.logo1.url} alt="" />
        </Link>
      )}
      {data && data.logo2?.url && (
        <Link to={"/" + uid}>
          <img className="mainIcon" src={data.logo2.url} alt="" />
        </Link>
      )}
    </div>
  );
};

export default MainLogos;
