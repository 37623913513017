import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useScript from "../../hooks/useScript";
import Footer from "../../components/Footer";
import checkIcon from "../../assets/check.png";
import backArrowIcon from "../../assets/arrow.png";
import { RichText } from "prismic-reactjs";
import { createGlobalStyle } from "styled-components";

import "./index.scss";
import MainLogos from "../../components/MainLogos";
import SEO from "../../components/SEO";

const ArtistPage = ({ artistsData, globalData, index, document }) => {
  const navigate = useNavigate();

  const [pageData] = useState(artistsData[index]);
  console.log(artistsData[index]);

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const status = useScript(
    "https://wct.live/app/sdk/v2/platform.js?campaign=" + pageData.id_wmg
  );

  // useAA("WMF Concours:Noel Advent Sweeps:Landing:Artist " + artistData.name);

  useEffect(() => {
    if (status === "ready" && pageData.id_wmg) {
      const connect_button = new window.WMGConnect(".wmg-button", {
        opt_in_form_target: "#formulaire",
        opt_in_thank_you_enabled: false,
      });

      connect_button.setCallback(function (connect_data) {
        setIsSubscribed(true);

        window.scrollTo(0, 0);
      });
    }
  }, [status]);

  useEffect(() => {
    if (pageData) {
      if (
        new Date() < new Date(pageData.startingdate) &&
        pageData.debugmode === false
      ) {
        navigate("/" + document.uid);
      }

      window.digitalData.page.pageInfo.pageName =
        "WMF Concours:Noel Advent Sweeps:Landing:" +
        pageData.artistname[0].text;
      window.digitalData.page.category.primaryCategory =
        "WMF Concours:Artist Page";
      window.digitalData.page.category.pageType =
        "artist page:" + pageData.artistname[0].text;

      window.document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
    }
  }, [pageData]);

  console.log(pageData);

  if (pageData) {
    const GlobalStyles = createGlobalStyle`
      html {
        --color--main: ${globalData.secondarytextcolor};
      }
    `;

    return (
      <>
        <SEO
          title={`Test ${pageData.artistname[0].text}`}
          description={`Bonjour je suis la description de ${pageData.artistname[0].text}`}
          image={pageData.homepagepicture.url}
        />
        <GlobalStyles />
        <main
          style={
            globalData
              ? {
                  backgroundColor: globalData.backgroundcolor,
                  border:
                    globalData.showborder === true
                      ? `solid 15px ${globalData.bordercolor}`
                      : "none",
                  color: globalData.maintextcolor,
                }
              : {}
          }
          className="artistPage"
        >
          <MainLogos uid={document.uid} data={globalData} />

          {/* Back icon */}
          <Link to={"/" + document.uid} className="backHome">
            <img src={backArrowIcon} alt="" />
          </Link>

          {!isSubscribed && (
            <>
              {pageData?.dotaphrase[0]?.text && (
                <>
                  <div className="lotPhrase">
                    <RichText
                      style={
                        globalData && {
                          color: globalData.maintextcolor,
                        }
                      }
                      className="lotPhrase"
                      render={pageData.dotaphrase}
                    />
                  </div>
                </>
              )}

              {pageData?.richtext && (
                <div className="lotPhrase">
                  <RichText className="lotPhrase" render={pageData.richtext} />
                </div>
              )}

              {pageData?.audio?.url && (
                <audio controls src={pageData.audio.url}></audio>
              )}

              <p
                style={
                  globalData && {
                    color: globalData.maintextcolor,
                  }
                }
                className="howMuchWinners"
              >
                {pageData.winnernumber ? (
                  pageData.winnernumber > 1 ? (
                    pageData.winnernumber + " gagnant.e.s"
                  ) : (
                    pageData.winnernumber + " gagnant.e"
                  )
                ) : (
                  <></>
                )}
              </p>
            </>
          )}

          {!isSubscribed && (
            <>
              {pageData?.dotapicture?.url && (
                <div className="boxPresentation">
                  <img src={pageData.dotapicture.url} alt="" />
                </div>
              )}

              {pageData?.idyttoembed && (
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube-nocookie.com/embed/${pageData.idyttoembed}?si=FS4rxJZXiLB6I6s9`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}

              <div className="contentContainer">
                <div id="formulaire"></div>
              </div>
            </>
          )}

          {/* Formulaire envoyé */}
          {isSubscribed && (
            <>
              <div className="contentContainer">
                {/* Participation prise en compte */}
                <div
                  className="priseEnCompte"
                  style={{
                    background: globalData.colorbackgroundcallbackparticipation,
                    color: globalData.colortextcallbackparticipation,
                  }}
                >
                  <img src={checkIcon} alt="" />
                  <RichText render={globalData.textcallbackparticipation} />
                </div>

                {/* Texte d'incitation */}
                {globalData.textincitationhome && (
                  <div className="pushCover">
                    <p>{globalData.textincitationhome}</p>
                    <Link
                      className="listen marginBottom"
                      to={"/" + document.uid}
                    >
                      {globalData.textctaincitationhome}
                    </Link>
                  </div>
                )}

                {/* Contenu à pousser */}
                <div className="pushCover">
                  {pageData?.callbacklinkfire?.url && (
                    <>
                      <p>{pageData.callbackdotatext[0].text}</p>

                      {pageData?.callbackimage?.url && (
                        <a href={pageData.callbacklinkfire.url} target="_blank">
                          <img src={pageData.callbackimage.url} alt="" />
                        </a>
                      )}

                      <a
                        href={pageData.callbacklinkfire.url}
                        target="_blank"
                        className="listen"
                      >
                        {pageData.ctacallbacktext || "Écouter"}
                      </a>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          <Footer globalData={globalData} />
        </main>
      </>
    );
  }
};

export default ArtistPage;
