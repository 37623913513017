import React from "react";
import "./index.scss";

const Footer = ({ globalData }) => {
  return (
    <footer
      className={`footer`}
      style={
        globalData
          ? {
              color: globalData.maintextcolor,
            }
          : {}
      }
    >
      <ul>
        <li className="footer-list-item light">
          <a
            href="http://www.warnermusic.fr/Mentions-legales"
            target="_blank"
            rel="noreferrer"
            style={
              globalData
                ? {
                    color: globalData.maintextcolor,
                  }
                : {}
            }
          >
            Mentions Légales
          </a>
        </li>
        <li className="footer-list-item light">
          <a
            href="https://www.wminewmedia.com/terms-of-use/fr/"
            target="_blank"
            rel="noreferrer"
            style={
              globalData
                ? {
                    color: globalData.maintextcolor,
                  }
                : {}
            }
          >
            Conditions générales d’utilisation
          </a>
        </li>
        <li className="footer-list-item light">
          <a
            href="https://www.wminewmedia.com/cookies-policy/fr/"
            target="_blank"
            rel="noreferrer"
            style={
              globalData
                ? {
                    color: globalData.maintextcolor,
                  }
                : {}
            }
          >
            Gestion des cookies
          </a>
        </li>
        <li className="footer-list-item light">
          <a
            href="https://www.wminewmedia.com/privacy/fr/"
            target="_blank"
            rel="noreferrer"
            style={
              globalData
                ? {
                    color: globalData.maintextcolor,
                  }
                : {}
            }
          >
            Politique de Traitement des données
          </a>
        </li>

        <li className="footer-list-item light">
          <a
            style={
              globalData
                ? {
                    color: globalData.maintextcolor,
                  }
                : {}
            }
            className="ot-sdk-show-settings"
          >
            Paramétrer mes cookies
          </a>
        </li>
      </ul>
      <p className="credit">© {new Date().getFullYear()} Warner Music France</p>
    </footer>
  );
};

export default Footer;
