import "./index.scss";

const Snow = () => {
  return (
    <div className="snow-container">
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      <div className="snow"></div>
      {window.innerWidth > 728 && (
        <>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
        </>
      )}
    </div>
  );
};

export default Snow;
