import { useEffect } from "react";

const useAA = (pageName) => {
  useEffect(() => {
    window.digitalData.page.pageInfo.pageName = pageName;
    document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
  }, []);
};

export default useAA;
