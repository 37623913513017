import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import useAA from "../hooks/useAA";
import MainLogos from "../components/MainLogos";
import { createGlobalStyle } from "styled-components";
import { hexToRgb } from "../utils";
import "./index.scss";

const MainPage = ({ dataDate, globalData, artistsData, document }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(globalData);

  useAA("WMF Concours:Noel Advent Sweeps:Landing:Homepage");

  if (globalData) {
    const GlobalStyles = createGlobalStyle`
      html {
        --color--blur: ${hexToRgb(globalData.blurartistcolor)};
      }
    `;

    return (
      <main
        className="mainPage"
        style={
          globalData
            ? {
                backgroundColor: globalData.backgroundcolor,
                border:
                  globalData.showborder === true
                    ? `solid 15px ${globalData.bordercolor}`
                    : "none",
                color: globalData.maintextcolor,
              }
            : {}
        }
      >
        <GlobalStyles />
        <div className="contentContainer">
          <MainLogos uid={document.uid} data={globalData} />

          <ul className="linksContainer">
            {artistsData &&
              artistsData.map((_data, index) => {
                console.log(_data);
                return (
                  <li
                    key={_data.path[0].text}
                    style={{
                      backgroundImage: `url(${_data.homepagepicture.url})`,
                    }}
                    className={`linkContainer ${
                      new Date().getTime() >
                        new Date(_data.startingdate).getTime() ||
                      _data.debugmode
                        ? ""
                        : "blurred"
                    }`}
                  >
                    <div className={`blurBG card-${index + 1}`}>
                      <div
                        className=""
                        style={
                          globalData
                            ? {
                                color: globalData.maintextcolor,
                              }
                            : {}
                        }
                      >
                        <p>
                          {("0" + new Date(_data.startingdate).getDate()).slice(
                            -2
                          )}
                          .{new Date(_data.startingdate).getMonth() + 1}
                        </p>
                        <h2>{_data.artistname[0].text}</h2>
                      </div>
                      {(new Date().getTime() >=
                        new Date(_data.startingdate).getTime() ||
                        _data.debugmode) && (
                        <>
                          <Link
                            style={
                              globalData
                                ? {
                                    color: globalData.maintextcolor,
                                    border: `solid 1px ${globalData.maintextcolor}`,
                                  }
                                : {}
                            }
                            to={document.uid + "/" + _data.path[0].text}
                          >
                            participer
                          </Link>
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>

          <Footer globalData={globalData} />
        </div>
      </main>
    );
  }
};

export default MainPage;
